import React from 'react';
import { useTranslation } from 'react-i18next';
import '../Styles/Solutions.css';
import Image from '../Images/image.png';
import imagesolutions from '../Images/imagesolutions.png';
import inventoryimage from '../Images/inventoryimage.png';
import softwareimage from '../Images/softwareimage.png';
import softwareimage2 from '../Images/softwareimage2.png';
import employeeimage from '../Images/employeeimage.png';
import reportsimage2 from '../Images/reportsimage2.png';
import reportsimage3 from '../Images/reportsimage3.png';
import reportsimage from '../Images/reportsimage.png';
import posterminal from '../Images/posterminal.png';
import posterminal2 from '../Images/posterminal2.png';
import posterminal3 from '../Images/posterminal3.png';
import card from '../Images/card.png';
import user from '../Images/user.png';


const Solutions = () => {
    const { t } = useTranslation();

    return (
        <div className="solutions-page">
            <div className="section">
                <h1>{t('title', 'Solutions')}</h1>
                <p>{t('description', 'You can accept payments instantly using the Exelo payment terminal. Exelo payment cards are seamlessly connected to EVC, Zaad, Sahal, and E-dahab accounts, ensuring fast and reliable transactions. With Exelo, every trade is streamlined, accurate, and hassle-free.')}</p>
            </div>

            <div className="content-container">
                <div className="text-section">
                    <h2 className="header-with-icon">
                    <div className="sale-icon-container">
                        <img src={card} alt="" />
                    </div>
                        <div className={'sale-header'}>
                    {t('easy_and_fast_payments', 'Easy and Fast Payments')}
                        </div>
                    </h2>
                    <p>{t('easy_payments_description', 'Speed up transactions and enhance customer satisfaction. With a single swipe or tap, process payments immediately.')}</p>
                </div>
                <div className="image-section">
                    <img src={Image} alt="Easy and Fast Payments" className="image" />
                </div>
            </div>
            <div className="sale-content-container">
                <div className="sale-image-section">
                    <img src={imagesolutions} alt="New Feature" className="Sale-image" />
                </div>
                <div className="sale-text-section">
                    <h2 className="header-with-icon">
                        {t('point_of_sale_software', 'One Device, One Flow')}
                        <div className="icon-container">
                            <img src={posterminal} alt="" />
                        </div>
                    </h2>
                    <div className='sale-text'>
                        <p>{t('point_of_sale_description', 'For the first time ever, all mobile money services come together in one device. Say goodbye to switching devices Exelo’s POS covers everything, all in a single system.')}</p>
                    </div>
                </div>
            </div>
            <div className="software-content-container">
                <div className="software-text-section">
                    <h2>
                        <div className="software-icon-container">
                            <img src={posterminal2} alt="" />
                        </div>
                        <div className={'software-header'}>
                            {t('point_of_sale_software', 'Transaction History')}
                        </div>
                    </h2>
                    <div className='software-text'>
                        <p>{t('software_description', 'Gain full visibility into your business with Exelo’s comprehensive transaction history. Track every payment in real-time, view detailed records, and monitor best selling products—all in one platform. With Exelo, you can analyze past transactions, track your pending transactions, identify key patterns, and make data-driven decisions that boost growth.')}</p>
                    </div>
                </div>
                <div className="software-image-section">
                    <img src={softwareimage} alt="Software Solutions" className="software-image" />
                    <img src={softwareimage2} alt="Software Solutions" className="software-image" />

                </div>
            </div>
            <div className="inventory-content-container">
                <div className="inventory-image-section">
                    <img src={inventoryimage} alt="Inventory Management" className="inventory-image" />
                </div>
                <div className="inventory-text-section">
                    <h2>
                        {t('inventory_pos_software', 'Inventory Management')}
                        <div className="icon-container">
                            <img src={posterminal3} alt="" />
                        </div>
                    </h2>
                    <div className='inventory-text'>
                        <p>{t('inventory_description', 'Take full control of your business with Exelo’s powerful inventory and sales management. Get real-time insights, set automatic reorder alerts, and generate detailed reports instantly.')}</p>
                    </div>
                </div>
            </div>
            <div className="reports-content-container">
                <div className="reports-text-section">
                    <h2>
                        <div className="reports-icon-container">
                            <i className="fa-regular fa-chart-bar"></i>
                        </div>
                        {t('reports', 'Reports')}
                    </h2>
                    <div className='reports-text'>
                        <p>{t('reports_description', 'Exelo’s reporting system provides clear, in-depth insights into both your transactions and inventory management. With our transaction reports, you can easily track all payments, categorize them by method— cash or card —and see your total revenue in different currencies. Our inventory reports help you monitor stock levels, item movement, and locations with real-time data, so you always know what’s available. Get a complete view of your business activities in one place, enabling smarter decisions and better control.')}</p>
                    </div>
                </div>
                <div className="reports-image-section">
                    <img src={reportsimage2} alt="Reports" className="reports-image" />
                    <img src={reportsimage3} alt="Reports" className="reports-image" />
                    <img src={reportsimage} alt="Reports" className="reports-image" />

                </div>
            </div>
            <div className="employee-content-container">
                <div className="employee-image-section">
                    <img src={employeeimage} alt="Employee Management" className="employee-image" />
                </div>
                <div className="employee-text-section">
                    <h2>
                        <div className={'employee-header'}>
                        {t('employee_management', 'Employee Management')}
                        </div>
                        <div className="employee-icon-container">
                            <img src={user} alt="" />
                        </div>

                    </h2>

                    <div className='employee-text'>
                        <p>{t('employee_description', 'Optimize your team’s performance with Exelo’s comprehensive employee management tools. Create accounts, assign roles, track shifts, monitor efficiency, manage permissions, and make everyday management effortless. With Exelo, you can foster an organized workspace that keeps your team engaged and driven, allowing your business to run smoothly and efficiently.')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Solutions;
