import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter as Router, Route} from 'react-router-dom';  // Import BrowserRouter here
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        {/* Move BrowserRouter to index.js */}
        <Router>
            <App />
        </Router>
    </React.StrictMode>
);
