import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom"; // Import Routes and Route
import Navbar from "./Components/Navbar";
import Home from "./Pages/Home";
import Solutions from "./Pages/Solutions";
import Partners from "./Pages/Partners";
import Contactus from "./Pages/Contactus";
import Subscription from "./Pages/Subscription";
import Accountdelete from "./Pages/accountdelete"; // Import the delete account page
import "./App.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

function App() {
    useEffect(() => {
        if (window.location.pathname === "/privacy") {
            window.location.href = "/Privacy.pdf"; // Redirect to Privacy.pdf
        }
    }, []);

    return (
        <div>
            {/* Navigation Bar */}
            <Navbar />
            <Routes>
                {/* Main route */}
                <Route
                    path="/"
                    element={
                        <div>
                            <section id="home">
                                <Home />
                            </section>
                            <section id="partners">
                                <Partners />
                            </section>
                            <section id="solutions">
                                <Solutions />
                            </section>
                            <section id="subscription">
                                <Subscription />
                            </section>
                            <section id="contactus">
                                <Contactus />
                            </section>
                        </div>
                    }
                />
                {/* Delete Account route */}
                <Route path="/deleteaccount" element={<Accountdelete />} />
            </Routes>
        </div>
    );
}

export default App;
