import React from "react";
import '../Styles/Subscription2.css';

const Subscription2 = ({ isModalOpen, toggleModal }) => {
    if (!isModalOpen) return null;

    return (
        <div className="modal-overlay" onClick={toggleModal}>
            <div className={"white-box"}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <h2>The Silver Package is perfect for businesses that are just starting out or those looking for a simple yet effective transaction management solution.</h2>
                <div className={"bullet-points"}>
                <ul>
                <li>Free subscription (excluding a one-time 510 SLSH verification fee).</li>
                <li>All payments are managed using the request transaction method.</li>
                <li>Manage sales and payments through Exelo’s intuitive POS system.</li>
                <li>Access to detailed transaction history.</li>
                </ul>
                </div>
                <p>Unlock the power of smart transaction management—without any monthly fees. This package gives you a strong foundation to manage your business efficiently while minimizing costs. Get started now and experience seamless payment processing.</p>
                <button onClick={toggleModal} className="silver-close-button">Close</button>
            </div>
        </div>
        </div>
    );
};

export default Subscription2;
