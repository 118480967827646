import React, { useState } from "react";
import "../Styles/accountdelete.css";

const AccountDelete = () => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [message, setMessage] = useState("");
    const [isError, setIsError] = useState(false);

    // Regex for phone number validation (adjust to your region's format if needed)
    const validatePhoneNumber = (number) => {
        const phoneRegex = /^[0-9]{10}$/; // Assuming a 10-digit phone number
        return phoneRegex.test(number);
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent form from refreshing the page

        if (validatePhoneNumber(phoneNumber)) {
            setMessage("Account deleted successfully!");
            setIsError(false);
        } else {
            setMessage("Invalid phone number. Please try again.");
            setIsError(true);
        }
    };

    return (
        <div className="delete-account">
            <div className="form-container">
                <h1>Delete Account</h1>
                <p className="subtitle">Enter your phone number to delete your account.</p>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        id="phone-number"
                        className="input-field"
                        placeholder="Enter your phone number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    <button type="submit" className="submit-button">Submit</button>
                </form>
                {message && (
                    <p className={`message ${isError ? "error" : "success"}`}>
                        {message}
                    </p>
                )}
            </div>
        </div>
    );
};

export default AccountDelete;
