import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Navbar.css";
import logo from "../Images/logo.png";

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false); // State for the navbar
    const navigate = useNavigate(); // For navigating between pages
    const location = useLocation(); // To track current location

    // Function to scroll to a section with height offset
    const scrollToSection = (sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
            const headerHeight = 40; // Fixed header height
            const elementPosition =
                sectionElement.getBoundingClientRect().top +
                window.pageYOffset -
                headerHeight;

            window.scrollTo({
                top: elementPosition,
                behavior: "smooth",
            });
        } else {
            console.warn(`Section with ID ${sectionId} not found.`);
        }
    };

    // Handle navigation to sections
    const handleNavigation = (sectionId) => {
        if (location.pathname !== "/") {
            // Always navigate to the root path (/) with the hash
            navigate(`/#${sectionId}`, { replace: true });
        } else {
            // Scroll directly to the section if already on the home page
            scrollToSection(sectionId);
        }
    };

    // Scroll to the correct section when the hash changes
    useEffect(() => {
        if (location.pathname === "/") {
            const sectionId = location.hash.replace("#", "");
            if (sectionId) {
                scrollToSection(sectionId);
            }
        }
    }, [location]);

    // Toggle Navbar
    const toggleNavbar = () => {
        setIsOpen(!isOpen);
        document.body.classList.toggle("no-scroll", !isOpen);
    };

    return (
        <nav className="header">
            <div className="logo-container">
                <a
                    href="/"
                    onClick={(e) => {
                        e.preventDefault();
                        toggleNavbar();
                        handleNavigation("home");
                    }}
                >
                    <img src={logo} alt="EXELO Logo" className="logo" />
                </a>
            </div>

            <div className={`navbar ${isOpen ? "active" : ""}`}>
                <a
                    href="home"
                    onClick={(e) => {
                        e.preventDefault();
                        toggleNavbar();
                        handleNavigation("home");
                    }}
                >
                    Home
                </a>
                <a
                    href="partners"
                    onClick={(e) => {
                        e.preventDefault();
                        toggleNavbar();
                        handleNavigation("partners");
                    }}
                >
                    Partners
                </a>
                <a
                    href="solutions"
                    onClick={(e) => {
                        e.preventDefault();
                        toggleNavbar();
                        handleNavigation("solutions");
                    }}
                >
                    Solutions
                </a>
                <a
                    href="subscription"
                    onClick={(e) => {
                        e.preventDefault();
                        toggleNavbar();
                        handleNavigation("subscription");
                    }}
                >
                    Subscription
                </a>
                <a
                    href="contactus"
                    onClick={(e) => {
                        e.preventDefault();
                        toggleNavbar();
                        handleNavigation("contactus");
                    }}
                >
                    Contact Us
                </a>
            </div>

            <button
                className={`menu-icon ${isOpen ? "open" : ""}`}
                onClick={toggleNavbar}
            >
                <div></div>
                <div></div>
                <div></div>
            </button>
        </nav>
    );
};

export default Navbar;
