import React from "react";
import '../Styles/Subscription3.css';

const Subscription3 = ({ isSilverModalOpen, toggleSilverModal }) => {
    if (!isSilverModalOpen) return null; // If modal is not open, return nothing

    return (
        <div className="silver-modal-overlay" onClick={toggleSilverModal}>
            <div className={"white-box"}>
                <div className="silver-modal-content" onClick={(e) => e.stopPropagation()}>
                    <h2>The Gold Package is a comprehensive plan designed for established businesses looking to elevate their operations with advanced tools and functionalities.</h2>
                    <div className={"silver-bullet-points"}>
                        <ul>
                            <li>Subscription fee of 10 USD per month.</li>
                            <li>Offers multiple payment methods, including simple request or register transactions, enabling businesses to choose the most convenient way to manage payments.</li>
                            <li>Advanced inventory management and employee tracking.</li>
                            <li>Automated fee calculations to save time and reduce errors.</li>
                            <li>Enhanced reporting tools and data analytics for business insights.</li>
                            <li>Premium support and service priority.</li>
                        </ul>
                    </div>
                    <p>Unlock the power of smart transaction management—without any monthly fees. This package gives you a strong foundation to manage your business efficiently while minimizing costs. Get started now and experience seamless payment processing.</p>
                    <button onClick={toggleSilverModal} className="close-button">Close</button>
                </div>
            </div>
        </div>
    );
};

export default Subscription3;
