import React from 'react';
import logo from '../Images/logo.png';

const updateDownlods= ()=> {
    fetch('http://localhost:3001/track-download') // This triggers the download from the server
        .then(response => {
            if (response.ok) {
                console.log("Download tracked successfully");
            }
        })
        .catch(error => console.error('Error tracking download:', error));
};

const DownloadAPK = () => {
    const downloadAPK = () => {
        const link = document.createElement('a');
        link.href = '/app-debug.apk'; // Relative path to the APK file in the public folder
        link.download = 'app-debug.apk';
        link.className = 'download-button'
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        //updateDownlods();

    };

    return (
        <div>
            <button className="download-button" onClick={downloadAPK}>
                <span className="button-text">Download Now</span>
            </button>
        </div>
    );
};

export default DownloadAPK;