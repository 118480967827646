import React from "react";
import { useTranslation } from 'react-i18next';
import '../Styles/Home.css';
import Downloadapk from './Downloadapk';


const Home = () => {
    const { t } = useTranslation();

    return (
        <div className="frontpage">
            <div className="text-container">
                <h1>{t('effortless_business_management')}</h1>
                <p>{t('Take control of your business like never before. Monitor sales, manage your inventory, and track every transaction effortlessly—all in real-time from one smart system.')}</p>
                <div className="download-buttons">
                    <Downloadapk />
                </div>
            </div>
            <div className="video-container">
                <video width="auto" height="auto" autoPlay loop muted playsInline>
                    <source src="/Videos/Video.mp4" type="video/mp4" />
                </video>
            </div>
        </div>
    );
}

export default Home;