import React from "react";
import '../Styles/Partners.css'
import Golis from '../Images/Golis.png';
import Ebirr from '../Images/Ebirr.png';
import Zaad from '../Images/Zaad.png';
import Dahab from '../Images/Dahab.png';
import EVC from '../Images/EVC.png';




const Partners = () => {
    return(
        <div className="partners-page">
            <h1>Next-Generation Merchant Solution in Africa</h1>
            <p>EXELO's exclusive technology, Tap on Any Device, is redefining the future of payment paths. Designed for businesses of all sizes, it sets new standards by seamlessly integrating transactions and inventory in real-time. Experience effortless business management and gain instant 360-degree insights into your company.</p>
            <h2 className={"text"}>
                A Trusted Partner with All Payment Methods!</h2>
            <div className="partners-image-section">
                <img src={Zaad} alt="New Feature" className="partners-image3" />
                <img src={Dahab} alt="New Feature" className="partners-image" />
                <img src={EVC} alt="New Feature" className="partners-image4" />
                <img src={Golis} alt="New Feature" className="partners-image2" />
                </div>
        </div>
    )
}
export default Partners;