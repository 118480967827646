import React, { useState } from "react";
import '../Styles/Subscription.css';
import Silver from "../Images/Silver.png";
import Gold from "../Images/Gold.png";
import Subscription2 from "./Subscription2";
import Subscription3 from "./Subscription3";

const Subscription = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSilverModalOpen, setIsSilverModalOpen] = useState(false);


    const toggleSilverModal = () => {
        setIsSilverModalOpen(!isSilverModalOpen);
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <div className="subscription-page">

            <div className="subscription-content">
                <div className="subscription-text-section">
                    <h1>Subscription</h1>
                    <p>Exelo offers a range of subscription plans designed to accommodate diverse business needs. Whether you’re a small startup looking to simplify your payment processes or a growing enterprise aiming to manage a complex range of transactions, our solutions are built to support your operations. With features that help optimize your financial workflows,
                        streamline inventory management, and elevate overall efficiency, Exelo’s packages empower you to focus on what truly matters: expanding your business.
                    </p>
                    <div className="subscription-description">
                        <p>Select the plan that aligns with your goals and experience seamless integration with Exelo's comprehensive POS system. Choose the free Silver package or subscribe to the Gold package to unlock the full potential of Exelo’s application and services.</p>
                    </div>
                </div>
                <div className="subscription-container">
                    <div className="subscription-image-section">
                        <div className="read-more-overlay" onClick={toggleModal}>
                            <span className="read-more-text">Gold Package</span>
                        </div>
                        <img src={Gold} alt="Silver Package" className="subscription-image" onClick={toggleSilverModal} />
                    </div>
                    <div className="subscription-image-section">
                        <div className="read-more-overlay" onClick={toggleSilverModal}>
                            <span className="read-more-text">Silver Package</span>
                        </div>
                        <img src={Silver} alt="Gold Package" className="subscription-image2" onClick={toggleModal} />
                    </div>
                </div>
            </div>


            <Subscription2 isModalOpen={isModalOpen} toggleModal={toggleModal} />
            <Subscription3 isSilverModalOpen={isSilverModalOpen} toggleSilverModal={toggleSilverModal} />
        </div>
    );
};

export default Subscription;
